#news_room_content_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--maincolorblack);
  > .title {
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    padding-top: 15%;
    padding-left: 15%;
  }
  > .contents {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10% 15%;
    width: 100%;
    > .card {
      width: 30%;
      margin-bottom: 8%;
      cursor: pointer;
      > .img_box {
        width: 100%;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          transition: 0.3s;
        }
      }
      > .name {
        font-size: 24px;
        font-weight: 500;
        line-height: 35px;
        padding-top: 1%;
      }
      > .date {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        padding-top: 5%;
      }
    }
    > .card:hover {
      > .img_box {
        > img {
          transform: scale(1.1);
        }
      }
    }
    > .card:nth-child(3n) {
      margin-right: 0px;
    }
  }
}

@media (max-width: 767px) {
  //모바일
  #news_room_content_wrap {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .contents {
      padding-left: 5%;
      padding-right: 5%;
      > .card {
        width: 48%;
        > .name {
          font-size: 16px;
          line-height: 25px;
        }
        > .date {
          font-size: 14px;
          line-height: 25px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #news_room_content_wrap {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .contents {
      > .card {
        width: 48%;
      }
    }
  }
}

@media (max-width: 1440px) {
  #news_room_content_wrap {
    > .title {
      padding-left: 5%;
      padding-right: 5%;
    }
    > .contents {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
