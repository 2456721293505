#ceo_content_wrap {
  color: var(--maincolorblack);
  padding-left: 15%;
  > .title_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20% 0%;
    > h2 {
      font-size: 60px;
      font-weight: 500;
      line-height: 90px;
    }
    > .des {
      font-size: 30px;
      font-weight: 500;
      line-height: 35px;
    }
  }
  > .contents {
    display: flex;
    flex-direction: column;
    position: relative;
    > .bg_img_box {
      position: absolute;
      height: 842px;
      > img {
        object-fit: none;
        height: 100%;
      }
    }
    > .content {
      display: flex;
      z-index: 1;
      > .left {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        padding-bottom: 100px;
        > .upper {
          padding-bottom: 40px;
        }
        > .under {
          display: flex;
          > .img_box {
            padding-left: 33px;
          }
        }
      }
      > .right {
        > .text_wrap {
          font-size: 18px;
          font-weight: 400;
          padding-top: 40px;
          padding-left: 40px;
          white-space: pre-line;
        }
        > .card_wrap {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 1000px;
          padding-top: 18px;

          > .card {
            border-left: 10px solid #3e533d;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            padding: 10px 0px 10px 20px;
            margin-bottom: 16px;
            margin-left: 45px;
            background-color: white;
            .fc_y {
              color: #f59607;
              font-weight: bold;
            }
            .fc_g {
              color: #01550e;
              font-weight: bold;
            }
          }
          .blank {
            height: 23px;
          }
          > .card_0,
          > .card_1,
          > .card_2,
          > .card_4,
          > .card_5 {
            width: 430px;
          }
          > .card_3,
          > .card_6 {
            width: 440px;
          }
          > .card_0 {
            font-size: 20px;
            font-weight: bold;
          }
          > .card_1 {
            font-size: 16px;
            font-weight: bold;
            line-height: 25px;
            color: #01550e;
          }
          > .card_2,
          > .card_3 {
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
          }
          > .card_4,
          > .card_5 {
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
          }
          > .card_6 {
            padding-right: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  #ceo_content_wrap {
    padding-left: 5%;
    > .title_wrap {
      > h2 {
        font-size: 20px;
        line-height: 20px;
      }
      > .des {
        font-size: 14px;
        line-height: 20px;
      }
    }
    > .contents {
      > .bg_img_box {
        position: relative;
        overflow: hidden;
        height: 530px;
        > img {
          object-fit: contain;
        }
      }
      > .content {
        flex-direction: column-reverse;
        > .left {
          align-items: start;
          padding-bottom: 0;
          > .upper {
            > .img_box {
              width: 180px;
              > img {
                width: 100%;
              }
            }
          }
          > .under {
            > .img_box:nth-child(1) {
              width: 30%;
            }
            > .img_box:nth-child(2) {
              width: 26%;
            }
            > .img_box:nth-child(3) {
              width: 34%;
            }
            > .img_box {
              > img {
                width: 100%;
              }
            }
          }
        }
        > .right {
          padding: 0 5%;
          > .text_wrap {
            padding: 0;
          }
          > .card_wrap {
            height: auto;
            > .card {
              width: auto;
              margin-left: 0;
              border-left: none;
              box-shadow: none;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #ceo_content_wrap {
    padding-left: 5%;
    > .title_wrap {
      > h2 {
        font-size: 35px;
        line-height: 35px;
      }
      > .des {
        font-size: 20px;
        line-height: 25px;
      }
    }
    > .contents {
      > .bg_img_box {
        position: relative;
      }
      > .content {
        flex-direction: column-reverse;
        > .right {
          padding: 5%;
        }
      }
    }
  }
}
