.App {
  max-width: 1920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  white-space: pre-line;
}

.fs_60 {
  font-size: 60px;
}
.fs_24 {
  font-size: 24px;
}
.fw_r {
  font-weight: 400;
}
.fw_m {
  font-weight: 500;
}
.lh_45 {
  line-height: 45px;
}
.lh_90 {
  line-height: 90px;
}
.ls_-2 {
  letter-spacing: -2px;
}
