#history_wrap {
  max-width: 1920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  padding-right: 5%;
  > .title_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20% 0%;
    > h2 {
      font-size: 60px;
      font-weight: 500;
      line-height: 90px;
    }
    > .des {
      font-size: 30px;
      font-weight: 500;
      line-height: 25px;
    }
  }
}

@media (max-width: 767px) {
  //모바일
  #history_wrap {
    padding: 0 5%;
    > .title_wrap {
      padding: 10% 0;
      > h2 {
        font-size: 20px;
        line-height: 20px;
      }
      > .des {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #history_wrap {
    padding-left: 5%;
    > .title_wrap {
      > h2 {
        font-size: 35px;
        line-height: 35px;
      }
      > .des {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
}
