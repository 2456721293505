.slider-container {
  width: 100%;
  height: 100%;
  position: relative;
  > .navigation_type_01_wrap {
    position: absolute;
    display: flex;
    align-items: center;
    color: #1e1e1e;
    z-index: 1;
    > .paging {
      width: 87px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > button {
        width: 36px;
        height: 36px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        padding: 0;
        &:hover {
          > svg {
            > circle {
              fill: #41563b;
            }
            > path {
              stroke: white;
            }
          }
        }
      }
    }
  }
  > .navigation {
    position: absolute;
    display: flex;
    align-items: center;
    color: #1e1e1e;
    z-index: 1;
    > .paging {
      width: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
        font-size: 12px;
        color: #bdbdbd;
      }
      > button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        padding: 0;
        height: 24px;
        > .pre {
          font-weight: bold;
        }
        > .next {
          color: #bdbdbd;
          transform: rotate(180deg);
        }
      }
    }
    > .bar {
      position: relative;
      width: 150px;
      height: 5px;
      background-color: #8b8b8b;
      > p {
        height: 100%;
        transition: 0.5s;
        margin: 0;
      }
    }
  }
}
