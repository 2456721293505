.net_zero_content_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding: 0px 15%;
  color: var(--maincolorblack);
  > h2 {
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    padding-top: 15%;
  }
  > .content {
    max-width: 1170px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-self: end;
    padding-top: 10%;
    > .card_wrap {
      width: 32%;
      border-radius: 5px;
      background-color: #f2f2f2;
      > .name {
        font-size: 28px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -2px;
        color: white;
        border-radius: 5px;
        padding: 15px 20px 15px 20px;
        margin: 10px 10px 0px 10px;
      }
      > .text {
        font-size: 20px;
        font-weight: 400;
        line-height: 45px;
        padding: 20px 30px;
      }
    }
  }
}
@media (max-width: 767px) {
  //모바일
  .net_zero_content_wrap {
    > h2 {
      font-size: 20px;
      line-height: 25px;
    }
    > .content {
      flex-direction: column;
      > .card_wrap {
        width: 100%;
        margin-bottom: 5%;
        > .name {
          font-size: 16px;
          line-height: 20px;
        }
        > .text {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .net_zero_content_wrap {
    > h2 {
      font-size: 40px;
      line-height: 50px;
    }
    > .content {
      > .card_wrap {
        > .name {
          font-size: 20px;
          line-height: 25px;
        }
        > .text {
          font-size: 16px;
          line-height: 35px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .net_zero_content_wrap {
    padding: 0 5%;
  }
}
