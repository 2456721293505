#trackRecord_client_content {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 10%;
  padding-bottom: 0;
  padding-left: 5%;
  padding-right: 5%;
  > .left {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: 860px;
    width: 60%;
    > .img_box {
      width: 276px;
      height: 142px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 5px;
      margin-bottom: 15px;
      box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    }
  }
  > .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 500px;
    width: 100%;
    > .list_wrap {
      padding: 5% 4%;
      background-color: #f2f2f2;
      > .title {
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
        border-bottom: 1px solid #999999;
      }
      > ul {
        padding-top: 4%;
        > li {
          font-size: 24px;
          font-weight: 400;
          line-height: 45px;
          list-style: disc;
          margin-left: 8%;
        }
      }
    }
    > .school_wrap {
      padding-bottom: 20%;
      margin-top: 35px;
    }
  }
}
@media (max-width: 767px) {
  //모바일
  #trackRecord_client_content {
    > .left {
      > .img_box {
        width: 45%;
        height: 82px;
        > img {
          width: 60%;
          height: 50%;
        }
      }
    }
    > .right {
      > .list_wrap {
        > .title {
          font-size: 20px;
          line-height: 35px;
        }
        > ul {
          > li {
            font-size: 14px;
            line-height: 25px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #trackRecord_client_content {
  }
}

@media (max-width: 1440px) {
}
@media (max-width: 1550px) {
  #trackRecord_client_content {
    flex-direction: column;
    > .left {
      max-width: none;
      width: 100%;
    }
    > .right {
      max-width: none;
      width: 100%;
    }
  }
}
