.introduction_video_wrap {
  display: flex;
  flex-direction: column;
  padding: 0 12%;
  > .title_wrap {
    color: #111111;
    padding-top: 15%;
    > h2 {
      font-size: 46px;
      font-weight: bold;
    }
  }
  > .video_wrap {
    position: relative;
    max-width: 1440px;
    width: 100%;
    margin-top: 50px;
    > video {
      width: 100%;
    }
    > button {
      width: 111px;
      height: 111px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
    > video:hover ~ button,
    > button:hover {
      visibility: visible;
      > svg {
        visibility: visible !important;
        > circle {
          fill: black;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .introduction_video_wrap {
    padding: 0 5%;
    > .title_wrap {
      padding-top: 64px;
      > h2 {
        font-size: 20px;
        line-height: 25px;
      }
    }
    > .video_wrap {
      margin-top: 10px;
      > button {
        display: none;
        // width: 48px;
        // height: 48px;
        // > svg {
        //   width: 100%;
        //   height: 100%;
        // }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .introduction_video_wrap {
    padding: 0 5%;
  }
}
