.s_wrap {
  width: 100%;
  border-top: 1px solid #999999;
  padding: 15%;
  > .title_wrap {
    > .title {
      font-size: 60px;
      font-weight: 500;
      line-height: 90px;
    }
    > .des {
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
    }
  }
  > .i_wrap {
    width: 100%;
    margin-top: 5%;
    > img {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .s_wrap {
    padding: 5%;
    > .title_wrap {
      > .title {
        font-size: 20px;
        line-height: 20px;
      }
      > .des {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .s_wrap {
    padding: 5%;
    > .title_wrap {
      > .title {
        font-size: 35px;
        line-height: 35px;
      }
      > .des {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  // 테블릿 가로
  .s_wrap {
    padding: 5%;
  }
}
