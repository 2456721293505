.client_wrap {
  max-width: 1920px;
  width: 100%;
  padding-top: 240px;
  padding-bottom: 220px;
  > .title_wrap {
    padding-left: 12%;
    color: var(--maincolorblack);
    > h2 {
      font-size: 46px;
      font-weight: bold;
    }
    > .des {
      font-size: 20px;
      font-weight: 400;
    }
  }
  > .content_wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    padding-top: 75px;
    > .row {
      position: relative;
      display: flex;
      // overflow: hidden;
      animation: animation_left 20s infinite linear;
      > .img_box {
        width: 276px;
        height: 142px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 5px;
        margin-bottom: 11px;
        margin-right: 20px;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
      }
    }
    > .row:nth-child(odd) {
      animation: animation_right 20s infinite linear;
    }
  }
}
@keyframes animation_left {
  0% {
    left: 0px;
  }
  50% {
    left: 200px;
  }
  100% {
    left: 0px;
  }
}
@keyframes animation_right {
  0% {
    left: 0px;
  }
  50% {
    left: -200px;
  }
  100% {
    left: 0px;
  }
}
@media (max-width: 767px) {
  //모바일
  .client_wrap {
    padding-top: 64px;
    padding-bottom: 60px;
    > .title_wrap {
      padding-left: 5%;
      > h2 {
        font-size: 20px;
        line-height: 25px;
      }
      > .des {
        font-size: 14px;
        line-height: 20px;
      }
    }
    > .content_wrap {
      padding-top: 30px;
      > .row {
        > .img_box {
          width: 80px;
          height: 50px;
          img {
            width: 70%;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .client_wrap {
    padding-top: 140px;
    padding-bottom: 160px;
    > .title_wrap {
      padding-left: 5%;
    }
    > .content_wrap {
      padding-top: 75px;
      > .row {
        > .img_box {
          width: 225px;
          height: 119px;
          img {
            width: 70%;
          }
        }
      }
    }
  }
}
