.it_environment_content_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding-bottom: 15%;
  color: var(--maincolorblack);
  > .title {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    padding-left: 15%;
    padding-top: 20%;
  }
  > .contents {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-top: 10%;
    > .des {
      max-width: 1150px;
      width: 100%;
      font-size: 30px;
      font-weight: 400;
      line-height: 55px;
    }
    > .card_wrap {
      padding-top: 3%;
      > .card {
        padding-top: 20%;
        max-width: 850px;
        width: 100%;
        > .img_box {
          width: 100%;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        > .name {
          padding-top: 1%;
          font-size: 40px;
          font-weight: 500;
          line-height: 60px;
        }
        > ul {
          padding-top: 1%;
          > li {
            font-size: 24px;
            font-weight: 400;
            line-height: 45px;
            list-style: disc;
            margin-left: 5%;
          }
        }
      }

      > .card:nth-child(1) {
        padding-top: 0px;
      }
    }
  }
}
@media (max-width: 767px) {
  //모바일
  .it_environment_content_wrap {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .contents {
      > .des {
        font-size: 16px;
        line-height: 25px;
      }
      > .card_wrap {
        > .card {
          > .name {
            font-size: 16px;
            line-height: 25px;
          }
          > ul {
            > li {
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .it_environment_content_wrap {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .contents {
      > .des {
        font-size: 20px;
        line-height: 35px;
      }
      > .card_wrap {
        > .card {
          > .name {
            font-size: 30px;
            line-height: 35px;
          }
          > ul {
            > li {
              font-size: 20px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .it_environment_content_wrap {
    > .title {
      padding-left: 5%;
    }
    > .contents {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
