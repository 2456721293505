.ci_signature_wrap {
  max-width: 1920px;
  width: 100%;
  padding: 0px 15%;
  > h2 {
    font-size: 40px;
    font-weight: 500;
    line-height: 90px;
    padding-top: 20px;
    padding-bottom: 10%;
  }
  > .ci_main_wrap {
    > .img_box {
      width: 100%;
      > img {
        width: 100%;
      }
    }
    > .ment {
      font-size: 28px;
      font-weight: 400;
      line-height: 55px;
      text-align: center;
      padding-top: 5%;
      word-break: break-all;
      white-space: wrap;
    }
  }
  > .ci_color_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 10%;
    > .ci_color_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;
      > .img_box {
        width: 100%;
        > img {
          width: 100%;
        }
      }
      > .text_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        padding-top: 10%;
        color: var(--maincolorblack);
        > span {
          display: block;
        }
      }
    }
  }
  > .ci_intverval_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 110px;
    > .interval_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 45%;
      > .img_box {
        width: 100%;
        > img {
          width: 100%;
        }
      }
      > span {
        font-size: 24px;
        font-weight: 400;
        line-height: 45px;
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .ci_signature_wrap {
    padding: 0 5%;
    > h2 {
      font-size: 16px;
      line-height: normal;
    }
    > .ci_main_wrap {
      > .ment {
        font-size: 14px;
        line-height: 20px;
        padding-top: 3%;
        text-align: left;
      }
    }
    > .ci_color_wrap {
      flex-direction: column;
      padding-top: 3%;
      > .ci_color_box {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding-bottom: 3%;
        > .img_box {
          width: 70%;
        }
        > .text_wrap {
          width: 25%;
          padding: 0;
          > span {
            display: block;
            font-size: 12px;
          }
        }
      }
    }
    > .ci_intverval_wrap {
      padding-top: 10%;
      flex-direction: column;
      > .interval_wrap {
        width: 100%;
        > span {
          font-size: 12px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .ci_signature_wrap {
    padding: 0 5%;
    > h2 {
      font-size: 30px;
    }
    > .ci_main_wrap {
      > .ment {
        font-size: 20px;
        line-height: 30px;
        padding-top: 3%;
        text-align: left;
      }
    }
    > .ci_color_wrap {
      flex-direction: column;
      > .ci_color_box {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding-bottom: 3%;
        > .img_box {
          width: 70%;
        }
        > .text_wrap {
          width: 25%;
          padding: 0;
          > span {
            display: block;
            font-size: 16px;
          }
        }
      }
    }
    > .ci_intverval_wrap {
      padding-top: 10%;
      > .interval_wrap {
        > span {
          font-size: 16px;
        }
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .ci_signature_wrap {
    padding: 0 5%;
  }
}
