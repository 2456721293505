#footer {
  max-width: 1920px;
  width: 100%;
  // height: 480px;
  color: var(--maincolorbalck);
  > .inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    > .sitemap_wrap {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 50px;
      border-top: 1px solid #999999;
      > .sitemap {
        display: flex;
        flex-direction: column;
        padding-right: 80px;
        > .title {
          font-size: 17px;
          font-weight: 500;
        }
        > ul {
          padding-top: 10px;
          > li {
            font-size: 14px;
            font-weight: 400;
            padding-bottom: 10px;
            cursor: pointer;
            > span:hover {
              border-bottom: 1px solid black;
            }
          }
        }
      }
    }
    > .office_info_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 75px 50px;
      border-top: 1px solid #999999;
      > .info_wrap {
        display: flex;
        flex-direction: column;
        padding-left: 35px;
        > .info {
          > span {
            font-size: 14px;
            font-weight: 400;
            margin-right: 15px;
          }
        }
        > div {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  #footer {
    > .inner {
      > .office_info_wrap {
        flex-direction: column;
        align-items: start;
        padding: 25px 16px;
        > .info_wrap {
          padding-left: 0;
        }
      }
    }
  }
}
@media (max-width: 1023px) {
  #footer {
    > .inner {
      > .sitemap_wrap {
        padding: 50px 30px;
        justify-content: start;
        > .sitemap {
          padding-right: 50px;
          padding-bottom: 30px;
        }
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1500px) {
  // 테블릿
  #footer {
    > .inner {
      > .sitemap_wrap {
        padding: 50px 10px;
        justify-content: space-evenly;
        > .sitemap {
          padding-right: 10px;
        }
      }
    }
  }
}
