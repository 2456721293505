.it_certification_content_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding-bottom: 20%;
  color: var(--maincolorblack);
  > .title {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    padding-top: 15%;
    padding-left: 15%;
  }
  > .contents {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 5%;
    padding-left: 15%;
    > .des {
      align-self: end;
      max-width: 1100px;
      width: 100%;
      font-size: 30px;
      font-weight: 400;
      line-height: 55px;
    }
    > .card_wrap {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      > .card {
        display: flex;
        flex-direction: column;
        width: 469px;
        height: 495px;
        padding: 47px 20px 0px 25px;
        margin-right: 16px;
        margin-top: 3%;
        border-radius: 5px;
        background-color: #f2f2f2;
        > .img_box {
          align-self: center;
          width: 177px;
          height: 177px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        > .text_wrap {
          > .name {
            font-size: 30px;
            font-weight: 500;
            line-height: 55px;
            padding-top: 42px;
          }
          > .text {
            font-size: 20px;
            font-weight: 400;
            line-height: 35px;
            padding-top: 5px;
            word-break: break-all;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .it_certification_content_wrap {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .contents {
      > .des {
        font-size: 16px;
        line-height: 25px;
      }
      > .card_wrap {
        > .card {
          width: 100%;
          height: auto;
          padding: 5%;
          flex-direction: row;
          align-items: center;
          margin-right: 0;
          > .img_box {
            width: 64px;
            height: 64px;
          }
          > .text_wrap {
            width: 80%;
            padding-left: 5%;
            > .name {
              padding: 0;
              font-size: 16px;
              line-height: 25px;
            }
            > .text {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .it_certification_content_wrap {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .contents {
      > .des {
        font-size: 20px;
        line-height: 35px;
      }
      > .card_wrap {
        > .card {
          width: 100%;
          height: auto;
          padding: 5%;
          flex-direction: row;
          align-items: center;
          margin-right: 0;
          > .img_box {
            width: 124px;
            height: 124px;
          }
          > .text_wrap {
            width: 80%;
            padding-left: 5%;
            > .name {
              padding: 0;
              font-size: 20px;
              line-height: 35px;
            }
            > .text {
              font-size: 16px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .it_certification_content_wrap {
    > .title {
      padding-left: 5%;
    }
    > .contents {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
