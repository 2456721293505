.title_format_2_wrap {
  max-width: 1920px;
  width: 100%;
  padding-top: 15%;
  padding-left: 15%;
  padding-bottom: 15%;
  border-bottom: 1px solid #999999;
  > h2 {
    font-size: 60px;
    font-weight: 500;
    color: var(--maincolorblack);
  }
}

@media (max-width: 767px) {
  //모바일
  .title_format_2_wrap {
    padding-left: 5%;
    > h2 {
      font-size: 20px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .title_format_2_wrap {
    padding-left: 5%;
    > h2 {
      font-size: 35px;
    }
  }
}
