.ci_download_wrap {
  width: 100%;
  padding: 0px 15% 15% 15%;
  > h2 {
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    padding-top: 15%;
    padding-bottom: 10%;
  }
  > .inner {
    display: flex;
    justify-content: space-between;
    padding: 0px 5%;
    > .content {
      display: flex;
      flex-direction: column;
      justify-content: end;
      width: 45%;
      > .img_box {
        width: 100%;
        > img {
          width: 100%;
        }
      }
      > .btn_wrap {
        display: flex;
        justify-content: space-between;
        padding-top: 50px;
        > button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45%;
          border: 1px solid #000000;
          border-radius: 5px;
          font-size: 20px;
          font-weight: 500;

          line-height: 45px;
          background-color: transparent;
          cursor: pointer;
          > img {
            padding-right: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .ci_download_wrap {
    padding: 0 5% 5% 5%;
    > h2 {
      font-size: 16px;
    }
    > .inner {
      flex-direction: column;
      > .content {
        width: 80%;
        padding-bottom: 15%;
        > .btn_wrap {
          padding-top: 5%;
          > button {
            > span {
              font-size: 12px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .ci_download_wrap {
    padding: 0 5% 5% 5%;
    > h2 {
      font-size: 40px;
    }
    > .inner {
      flex-direction: column;
      > .content {
        width: 80%;
        padding-bottom: 15%;
        > .btn_wrap {
          padding-top: 5%;
          > button {
            > span {
              font-size: 20px;
              line-height: 45px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  // 테블릿 가로
  .ci_download_wrap {
    padding: 0 5% 5% 5%;
  }
}
