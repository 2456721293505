#vision_wrap {
  max-width: 1920px;
  width: 100%;
  > .content {
    display: flex;
    justify-content: center;
    // margin-bottom: 260px;
    width: 100%;
    padding: 0 15% 15% 15%;
    > img {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  //모바일
  #vision_wrap {
    > .content {
      padding: 0 5% 5% 5%;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #vision_wrap {
    > .content {
      padding: 0 5% 5% 5%;
    }
  }
}
