#design_gallery_content {
  max-width: 1920px;
  width: 100%;
  > .title {
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    padding: 15% 15% 5% 15%;
  }
  > .content {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 200px;
    .card_wrap {
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
      > .card {
        width: 33%;
        margin-bottom: 1%;
        > .img_box {
          width: 100%;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  //모바일
  #design_gallery_content {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .content {
      .card_wrap {
        > .card {
          width: 49%;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #design_gallery_content {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
  }
}
@media (max-width: 1440px) {
  #design_gallery_content {
    > .title {
      padding-left: 5%;
    }
    > .content {
      padding-left: 5%;
      padding-right: 6%;
    }
  }
}
