.main_banner_wrap {
  width: 100%;
  position: relative;
  > .video_wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    > video {
      width: 100%;
    }
  }
  > .txt_wrap {
    position: absolute;
    color: white;
    width: 100%;
    // height: 100%;
    top: 33%;
    padding-left: 5%;

    // padding-top: 420px;
    // padding-left: 240px;
    > h2 {
      font-size: 40px;
      font-weight: 500;
      line-height: 30px;
    }
    > h3 {
      font-size: 60px;
      font-weight: bold;
      > .bar {
        position: relative;
        font-size: 40px;
        top: -10px;
      }
    }
    > p {
      width: 673px;
      font-size: 24px;
      font-weight: 300;
      line-height: 30px;
      padding-top: 24px;
      > span {
        font-weight: bold;
        font-size: 26px;
      }
    }
    > ul {
      display: flex;
      padding-top: 34px;
      padding-left: 8px;
      > li {
        font-size: 20px;
        font-weight: 300;
        padding-right: 10px;
        list-style: disc;
        margin-left: 40px;
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .main_banner_wrap {
    > .video_wrap {
      display: flex;
      justify-content: center;
      > video {
        width: auto;
        height: 780px;
      }
    }
    > .txt_wrap {
      width: 90%;
      top: 60%;
      > h2 {
        font-size: 18px;
      }
      > h3 {
        font-size: 20px;
        > .bar {
          position: relative;
          font-size: 20px;
          top: -2px;
        }
      }
      > p {
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        word-break: break-word;
        > span {
          font-weight: bold;
          font-size: 14px;
        }
      }
      > ul {
        > li {
          font-size: 12px;
          margin-left: 18px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  // 테블릿 세로
  .main_banner_wrap {
    > .txt_wrap {
      > h2 {
        font-size: 28px;
      }
      > h3 {
        font-size: 35px;
      }
      > p {
        font-size: 20px;
      }
      > ul {
        > li {
          font-size: 20px;
        }
      }
    }
  }
}
