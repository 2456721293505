.saas_dt_overview_wrap {
  max-width: 1920px;
  width: 100%;
  padding: 0px 15% 10% 15%;
  border-bottom: 1px solid #999999;
  > .title {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    padding: 15% 0;
    color: var(--maincolorblack);
  }
  > .overview {
    display: flex;
    flex-direction: column;
    justify-content: end;
    > .sub_title {
      font-size: 30px;
      font-weight: 400;
      line-height: 55px;
      padding-bottom: 5%;
      word-break: break-all;
    }
    > .card_wrap {
      display: flex;
      justify-content: end;
      flex-wrap: wrap;
      > .card {
        min-width: 290px;
        width: 30%;
        padding-bottom: 5%;
        border-radius: 5px;
        background-color: #f2f2f2;
        margin-bottom: 16px;
        margin-left: 1%;
        > .name {
          font-size: 28px;
          font-weight: 500;
          padding: 5px 15px;
          margin: 10px;
          letter-spacing: -2px;
          border-radius: 5px;
          color: white;
          background-color: var(--maincolorgreen);
        }
        > ul {
          padding-top: 10px;
          padding-left: 50px;
          padding-right: 30px;
          li {
            font-size: 20px;
            font-weight: 400;
            line-height: 45px;
            list-style: disc;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .saas_dt_overview_wrap {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .overview {
      > .sub_title {
        font-size: 16px;
        line-height: 25px;
      }
      > .card_wrap {
        > .card {
          width: 100%;
          min-height: 270px;
          > .name {
            font-size: 16px;
          }
          > ul {
            > li {
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .saas_dt_overview_wrap {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .overview {
      > .sub_title {
        font-size: 20px;
        line-height: 35px;
      }
      > .card_wrap {
        > .card {
          width: 48%;
          > .name {
            font-size: 20px;
          }
          > ul {
            > li {
              font-size: 16px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  // 테블릿 가로
}

@media (max-width: 1440px) {
  //공통
  .saas_dt_overview_wrap {
    padding: 0px 5% 15% 5%;
    > .overview {
      > .card_wrap {
        justify-content: space-between;
      }
    }
  }
}
