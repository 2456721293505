#header_wrap {
  max-width: 1920px;
  width: 100%;
  z-index: 999;
  > .d_wrap {
    position: fixed;
    max-width: 1920px;
    width: 100%;
    transition: 0.3s;
    &:hover {
      > .header {
        background-color: white;
        > .logo_wrap {
          > a {
            > .logo {
              > .geo {
                fill: var(--maincolorgreen);
              }
            }
          }
        }
        > .nav_wrap {
          > .menu {
            > li {
              color: var(--maincolorblack);
            }
          }
        }
      }
      > .child_menu_wrap {
        visibility: visible;
      }
    }
    > .header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      // padding-left: 240px;
      // padding-right: 310px;
      background-color: rgba(255, 255, 255, 0.75);
      transition: 0.3s;
      > .nav_wrap {
        display: flex;
        align-items: center;
        > .menu {
          display: flex;
          > li {
            font-size: 14px;
            font-weight: 500;
            color: var(--maincolorblack);
            margin: 0px 19px;
            border-bottom: 1px solid transparent;
            cursor: pointer;
            > span {
              display: flex;
              align-items: center;
              > img {
                padding-left: 3px;
              }
            }
          }
          > li:hover {
            border-bottom: 1px solid var(--maincolorgreen);
          }
        }
        > button {
          display: flex;
          align-items: center;
          padding: 4px 8px 4px 4px;
          border-radius: 5px;
          border: 1px solid #999999;
          background-color: transparent;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
        }
      }
      > .logo_wrap {
        > a {
          > .logo {
            > .geo {
              fill: var(--maincolorgreen);
            }
          }
        }
      }
    }
    > .child_menu_wrap {
      display: flex;
      justify-content: end;
      visibility: hidden;
      border-top: 1px solid var(--maincolorgreen);
      border-bottom: 1px solid var(--maincolorgray);
      background-color: white;
      > .child_menu {
        min-width: 180px;
        padding: 20px;
        border-left: 1px solid var(--maincolorgray);
        border-right: 1px solid var(--maincolorgray);
        color: var(--maincolorblack);
        > .name {
          font-size: 17px;
          font-weight: 500;
          margin-bottom: 15px;
        }
        > ul {
          > li {
            margin-bottom: 10px;
            > span {
              font-size: 15px;
              font-weight: 400;
            }
          }
        }
        &:hover {
          background-color: var(--maincolorgreen);
          color: white;
          > ul {
            > li {
              > span {
                cursor: pointer;
              }
              > span:hover {
                border-bottom: 1px solid var(--maincolorgray);
              }
            }
          }
        }
      }
      .blank {
        width: 245px;
      }
    }
  }
  > .m_wrap {
    width: 100%;
    position: fixed;
    display: none;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    // background-color: white;
    > .m_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 44px;
      padding: 0px 5%;
      > .logo_wrap {
        height: 70%;
        > a {
          height: 100%;
          > .logo {
            height: 100%;
            > .geo {
              height: 100%;
              fill: var(--maincolorgreen);
            }
          }
        }
      }
      > .ham_wrap {
        height: 70%;
        cursor: pointer;
        > img {
          height: 100%;
        }
      }
    }
    > .m_menu_wrap {
      padding: 5%;
      background-color: rgba(255, 255, 255, 0.9);
      // background-color: white;
      > ul {
        padding: 0 2% 4% 2%;
        > .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > .text {
            display: flex;
            align-items: center;
            font-size: 17px;
            > img {
              padding-left: 4px;
            }
          }
          > .ative {
            > img {
              transform: rotate(90deg);
              transition: 0.3s;
            }
          }
        }
        > .drop_wrap {
          display: none;
          flex-direction: column;
          font-size: 12px;
          font-weight: 400;
          padding-top: 1%;
          padding-left: 3%;
          > li {
            padding: 2% 0px;
          }
        }
        > .d_ative {
          display: flex;
        }
      }
      > button {
        display: flex;
        align-items: center;
        padding: 4px 8px 4px 4px;
        border-radius: 5px;
        border: 1px solid #999999;
        background-color: transparent;
        font-size: 14px;
        font-weight: 500;
        margin-left: 2%;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  #header_wrap {
    // width: 100vw;
    > .d_wrap {
      display: none;
    }
    > .m_wrap {
      display: flex;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
}

// @media (min-width: 768px) and (max-width: 991px) {
//   // 테블릿 세로
// }

// @media (min-width: 992px) and (max-width: 1199px) {
//   // 테블릿 가로
// }

@media (min-width: 1200px) {
  // 데스크탑 일반
}

//복사!!
@media (max-width: 767px) {
  //모바일
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
}
