#banner_wrap {
  max-width: 1680px;
  width: 100%;
  height: 410px;
  margin-left: 15%;
  overflow: hidden;
  > .img_box {
    max-width: 1680px;
    width: 100%;
    height: 100%;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  > .hidden_box {
    position: absolute;
    display: block;
    height: 410px;
    background-color: white;
    transition: 1s;
    z-index: 1;
  }
}

@media (max-width: 767px) {
  //모바일
  #banner_wrap {
    height: 140px;
    max-width: 100%;
    margin-left: 0%;
    > .img_box {
      max-width: 100%;
    }
    > .hidden_box {
      height: 140px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #banner_wrap {
    height: 295px;
    max-width: 100%;
    margin-left: 5%;
    > .img_box {
      max-width: 100%;
    }
    > .hidden_box {
      height: 295px;
    }
  }
}
