#history_content_wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 260px;
  > .left {
    width: 50%;
    padding: 0 5%;
    > .img_wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      > .img_box {
        width: 100%;
        padding-bottom: 5%;
        > img {
          width: 100%;
        }
      }
    }
  }
  > .right {
    width: 50%;
    padding-left: 5%;
    > img {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  //모바일
  #history_content_wrap {
    flex-direction: column;
    > .left {
      width: 100%;
      padding: 0;
      > .img_wrap {
        width: 100%;
        > .img_box {
          width: 100%;
          > img {
            width: 100%;
          }
        }
      }
    }
    > .right {
      width: 100%;
      padding: 0;
      > img {
        width: 100%;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
}
