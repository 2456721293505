.design_city_content_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding-bottom: 20%;
  color: var(--maincolorblack);
  > .title {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    padding-left: 15%;
    padding-top: 15%;
    color: var(--maincolorblack);
  }
  > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    > .des {
      max-width: 1100px;
      width: 100%;
      padding-top: 10%;
      font-size: 30px;
      font-weight: 400;
      line-height: 55px;
    }
    > .card_wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: end;
      > .card {
        display: flex;
        justify-content: end;
        width: 100%;
        padding-top: 10%;
        > .category {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 250px;
          height: 230px;
          padding: 0px 30px;
          border-radius: 10px;
          font-size: 30px;
          font-weight: 500;
          text-align: center;
          color: white;
          background-color: var(--maincolorgreen);
        }
        > .info_wrap {
          width: 850px;
          padding-left: 58px;
          > .info {
            padding-top: 30px;
            > .name {
              font-size: 40px;
              font-weight: 500;
              line-height: 60px;
            }
            > ul {
              padding-top: 25px;
              > li {
                font-size: 24px;
                font-weight: 400;
                line-height: 45px;
                list-style: disc;
                margin-left: 40px;
              }
            }
          }
          > .info:first-child {
            padding-top: 0px;
          }
        }
      }
      > .card:last-child {
        > .category {
          height: 430px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  //모바일
  .design_city_content_wrap {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .content {
      > .des {
        font-size: 16px;
        line-height: 25px;
      }
      > .card_wrap {
        > .card {
          flex-direction: column;
          > .category {
            width: 50%;
            height: auto;
            font-size: 16px;
            line-height: 25px;
            padding-top: 5px;
            padding-bottom: 5px;
          }
          > .info_wrap {
            width: 100%;
            min-height: 150px;
            border: 1px solid black;
            border-radius: 5px;
            padding: 3%;
            margin-top: 3%;
            > .info {
              > .name {
                font-size: 16px;
                line-height: 25px;
              }
              > ul {
                > li {
                  font-size: 14px;
                  line-height: 25px;
                  margin-left: 30px;
                }
              }
            }
          }
        }
        > .card:last-child {
          > .category {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .design_city_content_wrap {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .content {
      > .des {
        font-size: 20px;
        line-height: 35px;
      }
      > .card_wrap {
        > .card {
          flex-direction: column;
          > .category {
            width: 50%;
            height: auto;
            padding-top: 5px;
            padding-bottom: 5px;
          }
          > .info_wrap {
            width: 100%;
            min-height: 250px;
            border: 1px solid black;
            border-radius: 5px;
            padding: 3%;
            margin-top: 3%;
            > .info {
              > .name {
                font-size: 30px;
                line-height: 35px;
              }
              > ul {
                > li {
                  font-size: 20px;
                  line-height: 35px;
                }
              }
            }
          }
        }
        > .card:last-child {
          > .category {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .design_city_content_wrap {
    > .title {
      padding-left: 5%;
    }
    > .content {
      > .des {
        padding-left: 5%;
        padding-right: 5%;
      }
      > .card_wrap {
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }
}
