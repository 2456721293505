.it_carbon_neutral_city_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding: 0px 15%;
  color: var(--maincolorblack);
  > div {
    > h2 {
      font-size: 60px;
      font-weight: 500;
      letter-spacing: -2px;
      padding-top: 15%;
    }
    > .content {
      padding-top: 10%;
    }
  }
  > .city_wrap {
    display: flex;
    flex-direction: column;
    > .content {
      align-self: end;
      display: flex;
      flex-direction: column;
      align-items: end;
      max-width: 1200px;
      width: 100%;
      padding-top: 10%;
      > .des {
        font-size: 30px;
        font-weight: 400;
        line-height: 55px;
      }
      > .circle_wrap {
        max-width: 830px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 100px 0px;
        > .circle {
          max-width: 236px;
          // height: 272px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          > .img_box {
            width: 100%;
            height: 100%;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          > span {
            width: 130px;
            position: absolute;
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;
          }
        }
        > .circle:nth-child(2) {
          > span {
            width: 100px;
          }
        }
      }
      > .tech_wrap {
        width: 1100px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        > .box {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 360px;
          width: 100%;
          height: 130px;
          padding: 0px 70px;
          margin-bottom: 16px;
          background-color: #f2f2f2;
          border-radius: 5px;
          font-size: 24px;
          font-weight: 500;
          line-height: 30px;
          text-align: center;
        }
      }
    }
  }
  > .erp_wrap {
    padding-bottom: 15%;
    > .content {
      display: flex;
      > .cycle {
        width: 35%;
      }
      > .cycle_row {
        display: none;
      }
      > .step_wrap {
        display: flex;
        width: 65%;
        > .step_img {
          width: 32%;
          padding-left: 5%;
        }
        > .step_list {
          // width: 42%;
          padding-top: 30px;
          padding-left: 20px;
          > li {
            > .step {
              height: 210px;
              > .name {
                font-size: 40px;
                font-weight: 500;
                line-height: 60px;
                padding-bottom: 6px;
              }
              > ul {
                > .text {
                  font-size: 24px;
                  font-weight: 400;
                  line-height: 45px;
                  list-style: disc;
                  margin-left: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .it_carbon_neutral_city_wrap {
    > div {
      > h2 {
        font-size: 20px;
        line-height: 25px;
      }
    }
    > .city_wrap {
      > .content {
        > .des {
          font-size: 16px;
          line-height: 25px;
        }
        > .circle_wrap {
          justify-content: space-around;
          > .circle {
            width: 40%;
            > span {
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
        > .tech_wrap {
          width: 100%;
          > .box {
            width: 48%;
            font-size: 14px;
            line-height: 18px;
            padding: 0;
          }
        }
      }
    }
    > .erp_wrap {
      > .content {
        flex-direction: column;
        > .cycle {
          display: none;
        }
        > .cycle_row {
          display: block;
        }
        > .step_wrap {
          width: 100%;
          > .step_img {
            width: 40%;
            height: fit-content;
          }
          > .step_list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 25%;

            > li {
              > .step {
                height: auto;
                > .name {
                  font-size: 16px;
                  line-height: 25px;
                }
                > ul {
                  > .text {
                    font-size: 12px;
                    line-height: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .it_carbon_neutral_city_wrap {
    > div {
      > h2 {
        font-size: 40px;
        line-height: 50px;
      }
    }
    > .city_wrap {
      > .content {
        > .des {
          font-size: 20px;
          line-height: 35px;
        }
        > .circle_wrap {
          > .circle {
            width: 30%;
            > span {
              font-size: 20px;
            }
          }
        }
        > .tech_wrap {
          width: 100%;
          > .box {
            width: 32%;
            font-size: 20px;
            line-height: 25px;
            padding: 0;
          }
        }
      }
    }
    > .erp_wrap {
      > .content {
        flex-direction: column;
        > .cycle {
          display: none;
        }
        > .cycle_row {
          display: block;
        }
        > .step_wrap {
          width: 100%;
          > .step_list {
            > li {
              > .step {
                > .name {
                  font-size: 30px;
                  line-height: 45px;
                }
                > ul {
                  > .text {
                    font-size: 20px;
                    line-height: 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  // 태블릿 가로
  .it_carbon_neutral_city_wrap {
    padding: 0 5%;
    > .city_wrap {
      width: 100%;
    }
  }
}
