#business_wrap {
  color: var(--maincolorblack);
  > div {
    > .sub_title {
      font-size: 60px;
      font-weight: 500;
      line-height: 90px;
    }
  }
  > .s_wrap {
    padding-left: 15%;
    > .i_wrap {
      margin-bottom: 10%;
      > img {
        width: 100%;
      }
    }
  }
  > .business_list_wrap {
    padding-left: 15%;
    > .title {
      font-size: 60px;
      font-weight: 500;
      line-height: 90px;
      padding: 15% 0;
    }
    > .content_wrap {
      display: flex;
      justify-content: space-between;
      > .left {
        padding-right: 5%;
        > span {
          font-size: 60px;
          font-weight: 500;
          line-height: 90px;
        }
      }
      > .right {
        > .card {
          margin-bottom: 20%;
          > .name {
            font-size: 40px;
            font-weight: 500;
            line-height: 60px;
            padding-top: 13px;
          }
          > ul {
            padding-top: 10px;
            > li {
              font-size: 24px;
              font-weight: 400;
              line-height: 45px;
              list-style: disc;
              margin-left: 40px;
            }
          }
          > .img_box {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  > .m_wrap {
    padding: 0px 0px 260px 0px;
    padding-top: 10%;
    padding-bottom: 15%;
    display: flex;
    flex-direction: column;
    > .sub_title {
      padding-left: 15%;
    }
    > .des {
      padding-top: 14px;
      margin-left: 15%;
      font-size: 30px;
      font-weight: 500;
      line-height: 50px;
    }
    > .tap_wrap {
      display: flex;
      align-items: center;
      width: 100%;
      height: 130px;
      padding-left: 15%;
      background-color: white;
      border-bottom: 1px solid #999999;
      > ul {
        display: flex;
        align-items: center;
        height: 100%;
        > li {
          display: flex;
          min-width: 72px;
          height: 100%;
          flex-direction: column;
          align-items: center;
          font-size: 24px;
          font-weight: 500;
          line-height: 25px;
          padding: 50px 0px 0px 0px;
          margin: 0px 50px;
          color: var(--maincolorgray);
          cursor: pointer;
        }
        > li:first-child {
          padding-left: 0px;
        }
        > li:last-child {
          padding-right: 0px;
        }
        > .active {
          color: var(--maincolorblack);
        }
        > .active::after {
          content: "";
          width: 72px;
          display: block;
          margin-top: auto;
          border-bottom: 5px solid #111111;
        }
      }
    }
    > .map_wrap {
      padding-top: 80px;
      padding-left: 15%;
      width: 100%;
      height: 645px;
    }
    > .info_wrap {
      display: flex;
      flex-wrap: wrap;
      padding-top: 80px;
      padding-left: 240px;
      .fw_b {
        font-weight: bold;
      }
      .fc_y {
        color: #e41e6e;
      }
      .fc_b {
        color: #386de8;
      }
      .fc_g {
        color: #3cc344;
      }
      .fc_lg {
        color: #87c700;
      }
      .fc_r {
        color: #fb5852;
      }
      .ball {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        line-height: 14px;
        border-radius: 50%;
        color: white;
        background-color: #e41e6e;
      }
      > .info {
        display: flex;
        align-items: center;
        padding-right: 30px;
        font-size: 14px;
        padding-bottom: 50px;
        > .left {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-right: 26px;
          > .icon_text {
            width: 24px;
            height: 24px;
            border-radius: 3px;
            text-align: center;
            background-color: #2f96fc;
            color: white;
          }
          > .text {
            padding-top: 7px;
            font-size: 16px;
            font-weight: bold;
          }
          > .icon_box {
            width: 24px;
            height: 24px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      > .parking_wrap {
        > .right {
          > .parking_point_2 {
            padding-top: 7px;
          }
        }
      }
      > .subway_wrap {
        > .right {
          width: 280px;
          > .point_01 {
            > div:first-child {
              display: flex;
              align-items: center;
              > span:nth-child(2) {
                margin-left: 3px;
              }
            }
          }
          > .point_02 {
            padding-top: 7px;
          }
        }
      }
      > .bus_wrap {
        > .right {
          display: flex;
          > .point {
            margin-right: 16px;
            > .bus_route {
              padding-top: 6px;
              > div {
                > span {
                  margin-right: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  //모바일
  #business_wrap {
    > .s_wrap {
      padding-left: 0;
    }
    > .business_list_wrap {
      padding: 0 5%;
      > .title {
        font-size: 20px;
        line-height: 20px;
        padding: 12% 0;
      }
      > .content_wrap {
        flex-direction: column;
        > .left {
          > span {
            font-size: 20px;
            line-height: 20px;
          }
        }
        > .right {
          > .card {
            margin-bottom: 10%;
            > .name {
              font-size: 18px;
              line-height: 25px;
            }
            > ul {
              > li {
                font-size: 14px;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
    > .m_wrap {
      > .sub_title {
        padding-left: 5%;
        font-size: 20px;
        line-height: 20px;
      }
      > .des {
        margin-left: 5%;
        font-size: 14px;
        line-height: 25px;
      }
      > .tap_wrap {
        height: 52px;
        padding-left: 5%;
        font-size: 14px;
        line-height: 20px;
        > ul {
          > li {
            font-size: 14px;
            line-height: 20px;
            padding-top: 14px;
          }
        }
      }
      > .map_wrap {
        padding-left: 5%;
        padding-top: 24px;
      }
      > .info_wrap {
        padding-left: 5%;
        padding-top: 24px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #business_wrap {
    > .s_wrap {
      padding-left: 0;
    }
    > .business_list_wrap {
      padding: 0 5%;
      > .title {
        font-size: 35px;
        line-height: 35px;
      }
      > .content_wrap {
        flex-direction: column;
        > .left {
          padding-bottom: 1%;
          span {
            font-size: 35px;
            line-height: 35px;
          }
        }
        > .right {
          > .card {
            margin-bottom: 10%;
            > .name {
              font-size: 30px;
              line-height: 45px;
            }
            > ul {
              > li {
                font-size: 20px;
                line-height: 35px;
              }
            }
          }
        }
      }
    }
    > .m_wrap {
      > .sub_title {
        padding-left: 5%;
        font-size: 35px;
        line-height: 35px;
      }
      > .des {
        margin-left: 5%;
        font-size: 30px;
        line-height: 45px;
      }
      > .tap_wrap {
        height: 125px;
        padding-left: 5%;
        font-size: 24px;
      }
      > .map_wrap {
        padding-left: 5%;
      }
      > .info_wrap {
        padding-left: 5%;
      }
    }
  }
}
