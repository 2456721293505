.saas_dt_platform_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding: 15% 15% 5% 15%;
  > .contents {
    width: 100%;
    > .i_wrap {
      width: 100%;
      > img {
        width: 100%;
      }
    }
  }
}
@media (max-width: 1440px) {
  //모바일
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
}

@media (max-width: 1440px) {
  //모바일
  .saas_dt_platform_wrap {
    padding: 15% 5%;
  }
}
