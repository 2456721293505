.certification_card_wrap {
  width: 100%;
  padding-bottom: 15%;
  padding-left: 25%;
  padding-right: 5%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  // margin-bottom: 30px;
  > img {
    width: 29%;
    margin-bottom: 30px;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.4);
  }
}

@media (max-width: 1024px) {
  .certification_card_wrap {
    padding-left: 5%;
  }
}
