#ceo_organization_chart_wrap {
  max-width: 1920px;
  width: 100%;
  padding: 0px 15% 15% 15%;
  > .title {
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    padding-top: 15%;
  }
  > .content {
    display: flex;
    justify-content: center;
    padding-top: 15%;
    > .img_box {
      width: 100%;
      padding: 0 5%;
      // height: 1402px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  #ceo_organization_chart_wrap {
    padding: 0px 5% 5% 5%;
    > .title {
      font-size: 20px;
      line-height: 20px;
    }
    > .content {
      > .img_box {
        padding: 0;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #ceo_organization_chart_wrap {
    padding: 0px 5% 5% 5%;
    > .title {
      font-size: 35px;
      line-height: 35px;
    }
    > .content {
      > .img_box {
        padding: 0;
      }
    }
  }
}
