.ci_scroll_event_wrap {
  max-width: 1920px;
  width: 100%;
  position: relative;
  > .inner {
    width: 100%;
    display: flex;
    > .left {
      width: 50%;
      > img {
        width: 100%;
      }
    }
    > .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      padding: 0 5%;
      > .ment {
        width: 100%;
        font-size: 40px;
        font-weight: 500;
        letter-spacing: -2px;
        line-height: 60px;
        > ul {
          padding-bottom: 30px;
          > li {
            transition: 1s;
            padding-bottom: 80px;
            &:last-child {
              padding-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .ci_scroll_event_wrap {
    > .inner {
      flex-direction: column;
      > .left {
        width: 100%;
      }
      > .right {
        width: 100%;
        padding-top: 5%;
        > .logo_wrap {
          width: 80px;
          > img {
            width: 100%;
            padding-bottom: 5%;
          }
        }
        > .ment {
          > ul {
            font-size: 16px;
            line-height: 25px;
            > li {
              padding-bottom: 5%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .ci_scroll_event_wrap {
    > .inner {
      flex-direction: column;
      > .left {
        width: 100%;
      }
      > .right {
        width: 100%;
        padding-top: 5%;
        > .logo_wrap {
          width: 180px;
          > img {
            width: 100%;
            padding-bottom: 5%;
          }
        }
        > .ment {
          > ul {
            font-size: 25px;
          }
        }
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  // 테블릿가로!
  .ci_scroll_event_wrap {
    > .inner {
      flex-direction: column;
      > .left {
        width: 100%;
      }
      > .right {
        width: 100%;
        padding-top: 5%;
      }
    }
  }
}
