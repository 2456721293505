#home_track_record_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  overflow: hidden;
  padding-left: 12%;
  > .title_wrap {
    padding-top: 240px;
    > h2 {
      font-size: 60px;
      font-weight: 500;
      line-height: 90px;
    }
    > span {
      font-size: 24px;
      font-weight: 400;
      line-height: 45px;
    }
  }
  > .content {
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    height: 750px;
    > .card_wrap {
      position: absolute;
      display: flex;
      align-items: end;
      top: 0;
      padding-top: 75px;
      transition: 0.5s;
      > .pre {
        width: 650px;
        height: 490px;
        opacity: 0.5;
      }
      > .active {
        width: 650px;
        height: 490px;
      }
      > .next {
        width: 300px;
        height: 320px;
        opacity: 0.5;
      }
      > .card {
        margin-right: 16px;
        transition: 0.5s;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    > .name {
      font-size: 40px;
      font-weight: 500;
      line-height: 60px;
    }
    > button {
      align-self: flex-start;
      margin-top: 20px;
      font-size: 20px;
      font-weight: 500;
      // line-height: 40px;
      border: none;
      background-color: transparent;
      transition: 0.5s;
      > span {
        margin-left: 7px;
      }
    }
    > .paging {
      display: flex;
      justify-content: space-between;
      align-self: center;
      width: 100px;
      > button {
        width: 36px;
        height: 36px;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      > .btn {
        &:hover {
          > svg {
            > circle {
              fill: #41563b;
            }
            > path {
              stroke: white;
            }
          }
        }
      }
      > .n {
        opacity: 0.5;
        cursor: auto;
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  #home_track_record_wrap {
    padding-left: 5%;
    > .title_wrap {
      padding-top: 64px;
      > h2 {
        font-size: 20px;
        line-height: 25px;
      }
      > span {
        font-size: 14px;
        line-height: 20px;
      }
    }
    > .content {
      height: 300px;
      > .card_wrap {
        padding-top: 30px;
        > .pre {
          width: 213px;
          height: 160px;
          opacity: 0.5;
        }
        > .active {
          width: 213px;
          height: 160px;
        }
        > .next {
          width: 109px;
          height: 111px;
          opacity: 0.5;
        }
      }
      > .name {
        font-size: 16px;
        line-height: 20px;
      }
      > button {
        font-size: 14px;
        line-height: 15px;
        > span {
          display: inline-block;
          width: 14px;
          > img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #home_track_record_wrap {
    padding-left: 5%;
    > .title_wrap {
      > h2 {
        font-size: 35px;
        line-height: 55px;
      }
      > span {
        font-size: 24px;
        line-height: 45px;
      }
    }
    > .content {
      height: 660px;
      > .card_wrap {
        padding-top: 30px;
      }
      > .name {
        font-size: 30px;
        line-height: 30px;
      }
      > button {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
}
