.project_performance_content {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  color: var(--maincolorblack);
  > .title {
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    letter-spacing: -2px;
    padding-left: 15%;
    padding-top: 15%;
    padding-bottom: 10%;
  }
  > .content {
    max-width: 1920px;
    width: 100%;
    display: flex;
    flex-direction: column;
    > .card_wrap {
      display: flex;
      justify-content: end;
      width: 100%;
      padding: 2% 0px;
      border-bottom: 1px solid #999999;
      > .card {
        display: flex;
        flex-direction: column;
        align-items: end;
        max-width: 850px;
        width: 100%;
        margin-right: 2%;
        > .name {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 40px;
          font-weight: 500;
          line-height: 60px;
          cursor: pointer;
          > button {
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            transition: 0.5s;
            cursor: pointer;
          }
          > .btn_active {
            transform: rotate(180deg);
          }
        }
        > ul {
          width: 100%;
          padding-top: 2%;
          display: none;
          .fc_y {
            color: #ed6c00;
          }
          li {
            font-size: 24px;
            font-weight: 400;
            line-height: 45px;
            list-style: disc;
            margin-left: 40px;
          }
        }
        > .accordion_active {
          display: block;
        }
      }
    }
    > .card_wrap:nth-child(1) {
      border-top: 1px solid #999999;
    }
  }
}
@media (max-width: 767px) {
  //모바일
  .project_performance_content {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .content {
      > .card_wrap {
        > .card {
          > .name {
            font-size: 16px;
            line-height: 25px;
            > button {
              width: 24px;
              height: 24px;
              > img {
                width: 100%;
                height: 100%;
              }
            }
          }
          > ul {
            > li {
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .project_performance_content {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .content {
      > .card_wrap {
        > .card {
          > .name {
            font-size: 30px;
            line-height: 35px;
          }
          > ul {
            > li {
              font-size: 20px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .project_performance_content {
    > .title {
      padding-left: 5%;
    }
    > .content {
      > .card_wrap {
        padding-left: 5%;
        padding-right: 2%;
      }
    }
  }
}
