.ci_color_system_wrap {
  max-width: 1920px;
  width: 100%;
  padding: 0px 15%;
  > h2 {
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    padding-top: 15%;
    padding-bottom: 10%;
  }
  > .bi_main_wrap {
    > .ment {
      font-size: 28px;
      font-weight: 400;
      line-height: 55px;
    }
    > .imgs_wrap {
      display: flex;
      justify-content: space-between;
      padding: 5% 0;
      > .img_box {
        width: 32%;
        > img {
          width: 100%;
        }
      }
    }
  }
  > .bi_color_wrap {
    > .pantone_wrap {
      display: flex;
      justify-content: space-between;
      > .text_wrap {
        display: flex;
        justify-content: space-between;
        width: 30%;
        > .code {
          width: 60%;
          font-size: 24px;
          font-weight: 500;
          line-height: 45px;
        }
        > .name {
          width: 40%;
          font-size: 24px;
          font-weight: 400;
          line-height: 30px;
        }
      }
      > .img_box {
        width: 70%;
        > img {
          width: 100%;
        }
      }
      &:first-child {
        padding-bottom: 5%;
      }
    }
  }
}
@media (max-width: 767px) {
  //모바일
  .ci_color_system_wrap {
    padding: 0 5%;
    > h2 {
      font-size: 16px;
      line-height: 20px;
      padding-top: 15%;
      padding-bottom: 15%;
    }
    > .bi_main_wrap {
      > .ment {
        font-size: 14px;
        line-height: 20px;
      }
      > .imgs_wrap {
        flex-direction: column;
        > .img_box {
          width: 100%;
          padding-bottom: 5%;
        }
      }
    }
    > .bi_color_wrap {
      > .pantone_wrap {
        flex-direction: column-reverse;
        > .text_wrap {
          width: 100%;
          > .code {
            width: auto;
            font-size: 14px;
            line-height: 20px;
          }
          > .name {
            width: auto;
            font-size: 14px;
            line-height: 15px;
          }
        }
        > .img_box {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .ci_color_system_wrap {
    padding: 0 5%;
    > h2 {
      font-size: 40px;
      line-height: 50px;
      padding-top: 15%;
      padding-bottom: 15%;
    }
    > .bi_main_wrap {
      > .ment {
        font-size: 20px;
        line-height: 30px;
      }
      > .imgs_wrap {
        flex-direction: column;
        > .img_box {
          width: 100%;
          padding-bottom: 5%;
        }
      }
    }
    > .bi_color_wrap {
      > .pantone_wrap {
        flex-direction: column-reverse;
        > .text_wrap {
          width: 100%;
          > .code {
            width: auto;
          }
          > .name {
            width: auto;
          }
        }
        > .img_box {
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  // 테블릿 가로
  .ci_color_system_wrap {
    padding: 0 5%;
    > .bi_main_wrap {
      > .imgs_wrap {
        flex-direction: column;
        > .img_box {
          width: 100%;
          padding-bottom: 5%;
        }
      }
    }
    > .bi_color_wrap {
      > .pantone_wrap {
        flex-direction: column-reverse;
        > .text_wrap {
          width: 100%;
          > .code {
            width: auto;
          }
          > .name {
            width: auto;
          }
        }
        > .img_box {
          width: 100%;
        }
      }
    }
  }
}
