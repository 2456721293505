.energy_step_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  > .title {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    padding-top: 15%;
    padding-left: 15%;
    color: var(--maincolorblack);
  }
  > .contents {
    align-self: end;
    padding-right: 10%;
    color: var(--maincolorblack);
    > .content {
      display: flex;
      max-width: 830px;
      width: 100%;
      padding-top: 10%;
      > .left {
        position: relative;
        width: 243px;
        height: 272px;
        > .img_box {
          width: 100%;
          height: 100%;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        > span {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0%;
          width: 100%;
          height: 100%;
          font-size: 40px;
          font-weight: 500;
          line-height: 60px;
        }
      }
      > .right {
        max-width: 587px;
        width: 100%;
        padding-left: 40px;
        > .name {
          font-size: 40px;
          font-weight: 500;
          line-height: 60px;
          padding-bottom: 2%;
        }
        > .des {
          font-size: 24px;
          font-weight: 400;
          line-height: 45px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  //모바일
  .energy_step_wrap {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .contents {
      padding: 0% 10% 10% 5%;
      > .content {
        padding-top: 5%;
        > .left {
          width: 96px;
          height: 106px;
          > span {
            font-size: 16px;
            line-height: 20px;
          }
        }
        > .right {
          width: 80%;
          padding-left: 20px;
          > .name {
            font-size: 16px;
            line-height: 20px;
          }
          > .des {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .energy_step_wrap {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .contents {
      > .content {
        > .left {
          width: 180px;
          height: 200px;
          > span {
            font-size: 30px;
            line-height: 30px;
          }
        }
        > .right {
          > .name {
            font-size: 30px;
            line-height: 45px;
          }
          > .des {
            font-size: 20px;
            line-height: 35px;
          }
        }
      }
    }
  }
}
@media (max-width: 1025px) {
  .energy_step_wrap {
    > .title {
      padding-left: 5%;
    }
    > .contents {
      align-self: center;
      padding-right: 0%;
    }
  }
}
