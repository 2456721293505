#news_article_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  color: var(--maincolorblack);
  > .news_header {
    display: flex;
    flex-direction: column;
    // padding: 260px 240px 0px 240px;
    padding: 10% 5% 10% 15%;
    border-bottom: 1px solid #999999;
    > button {
      width: 52px;
      height: 52px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    > .title {
      font-size: 60px;
      font-weight: 500;
      line-height: 90px;
      padding-top: 1%;
    }
    > .date {
      font-size: 24px;
      font-weight: 400;
      line-height: 25px;
      padding-top: 10%;
    }
  }
  > .article_wrap {
    padding: 5% 15%;
    border-bottom: 1px solid #999999;
    font-size: 24px;
    font-weight: 400;
    line-height: 45px;
    white-space: pre-line;
  }
  > .n_wrap {
    padding: 3% 15% 10% 15%;
    > .m_wrap {
      display: flex;
      align-items: center;
      cursor: pointer;
      > .s {
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
      }
      > .t {
        font-size: 30px;
        font-weight: 400;
        line-height: 35px;
        padding-left: 10%;
      }
    }

    > .m_wrap:hover {
      > .t {
        > span {
          border-bottom: 1px solid #111111;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  //모바일
  #news_article_wrap {
    > .news_header {
      > button {
        width: 24px;
        height: 24px;
      }
      > .title {
        font-size: 20px;
        line-height: 25px;
      }
      > .date {
        font-size: 16px;
        line-height: 25px;
      }
    }
    > .article_wrap {
      font-size: 16px;
      line-height: 30px;
    }
    > .n_wrap {
      > .m_wrap {
        > .s {
          font-size: 16px;
          line-height: 25px;
        }
        > .t {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #news_article_wrap {
    > .news_header {
      > .title {
        font-size: 40px;
        line-height: 50px;
      }
      > .date {
        font-size: 24px;
        line-height: 25px;
      }
    }
    > .article_wrap {
      font-size: 16px;
      line-height: 30px;
    }
    > .n_wrap {
      > .m_wrap {
        > .s {
          font-size: 20px;
          line-height: 25px;
        }
        > .t {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  #news_article_wrap {
    > .news_header {
      padding-left: 5%;
      padding-right: 5%;
    }
    > .article_wrap {
      padding-left: 5%;
      padding-right: 5%;
    }
    .n_wrap {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
