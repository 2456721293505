.representative_performance_non_residence_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding: 0 15%;
  color: var(--maincolorblack);
  > .title {
    font-size: 40px;
    font-weight: 500;
    line-height: 90px;
    letter-spacing: -2px;
    padding-top: 20%;
    padding-bottom: 10%;
  }
  > .content {
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-self: end;
    flex-wrap: wrap;
    > .card {
      max-width: 380px;
      width: 32%;
      margin-bottom: 100px;
      margin: 0px 1% 10% 0;
      > .img_box {
        width: 100%;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      > .name {
        font-size: 24px;
        font-weight: 500;
        line-height: 25px;
        padding-top: 1%;
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .representative_performance_non_residence_wrap {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .content {
      justify-content: space-between;
      > .card {
        width: 48%;
        > .name {
          font-size: 14px;
          line-height: 25px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .representative_performance_non_residence_wrap {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .content {
      justify-content: space-between;
      > .card {
        width: 48%;
        max-width: none;
        > .name {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .representative_performance_non_residence_wrap {
    padding: 0 5%;
  }
}
