#business_info_wrap {
  max-width: 1920px;
  width: 100%;
  > .title_wrap {
    width: 100%;
    padding: 240px;
    > .title {
      font-size: 60px;
      font-weight: 500;
      line-height: 90px;
    }
    > .logo_box {
      margin-top: 100px;
      width: 82px;
      height: 84px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  > .card_wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    > .card {
      display: flex;
      align-items: center;
      width: 100%;
      > .left {
        width: 50%;
        height: 100%;
        > .img_box {
          width: 100%;
          height: 100%;
          overflow: hidden;
          > img {
            width: 100%;
            height: 100%;
            transition: 0.5s;
            vertical-align: bottom;
          }
        }
      }
      > .right {
        width: 50%;
        padding-left: 5%;
        padding-right: 10%;
        > .name {
          // width: 500px;
          font-size: 60px;
          font-weight: 500;
          line-height: 90px;
        }
        > .des {
          padding-top: 10px;
          font-size: 24px;
          font-weight: 400;
          line-height: 45px;
        }
        > button {
          margin-top: 50px;
        }
      }
    }
    > .card:hover {
      > .left {
        > .img_box {
          > img {
            scale: 1.1;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  #business_info_wrap {
    > .title_wrap {
      padding: 64px 16px;
      > .title {
        font-size: 20px;
        line-height: 25px;
      }
    }
    > .card_wrap {
      > .card {
        flex-direction: column;
        > .left {
          width: 100%;
        }
        > .right {
          width: 100%;
          padding-top: 10px;
          padding-bottom: 25px;
          > .name {
            font-size: 20px;
            line-height: 25px;
          }
          > .des {
            font-size: 14px;
            line-height: 20px;
          }
          > button {
            font-size: 14px;
            line-height: 15px;
            margin-top: 15px;
            > span {
              display: inline-block;
              width: 14px;
              > img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  // 테블릿
  #business_info_wrap {
    > .title_wrap {
      padding: 240px 48px;
    }
    > .card_wrap {
      > .card {
        flex-direction: column;
        > .left {
          width: 100%;
        }
        > .right {
          width: 100%;
          padding-top: 40px;
          padding-bottom: 80px;
        }
      }
    }
  }
}
