.it_decarbonization_content_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding: 0 0 10% 0;
  border-bottom: 1px solid #999999;
  color: var(--maincolorblack);
  > .title {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    padding-top: 15%;
    padding-left: 15%;
    color: var(--maincolorblack);
  }
  > .contents {
    align-self: end;
    max-width: 1100px;
    width: 100%;
    padding-top: 10%;
    > .des {
      font-size: 30px;
      font-weight: 400;
      line-height: 55px;
    }
    > .card_wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 50px;
      padding-bottom: 34px;
      > .card {
        width: 357px;
        height: 460px;
        border-radius: 5px;
        background-color: #f2f2f2;
        margin-bottom: 16px;
        > .name {
          height: 52px;
          font-size: 28px;
          font-weight: 500;
          letter-spacing: -2px;
          color: white;
          border-radius: 5px;
          padding-top: 5px;
          padding-left: 15px;
          margin: 10px 10px 0px 10px;
          background-color: var(--maincolorgreen);
        }
        > ul {
          padding: 20px;
          > li {
            font-size: 20px;
            font-weight: 400;
            line-height: 45px;
            margin-left: 30px;
            list-style: disc;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  //모바일
  .it_decarbonization_content_wrap {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .contents {
      > .des {
        font-size: 16px;
        line-height: 25px;
      }
      > .card_wrap {
        > .card {
          width: 100%;
          height: auto;
          padding-bottom: 5%;
          > .name {
            font-size: 16px;
            height: auto;
            padding-bottom: 5px;
          }
          > ul {
            > li {
              font-size: 12px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .it_decarbonization_content_wrap {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .contents {
      > .des {
        font-size: 20px;
        line-height: 35px;
      }
      > .card_wrap {
        > .card {
          width: 48%;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .it_decarbonization_content_wrap {
    > .title {
      padding-left: 5%;
    }
    > .contents {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
