#business_info_type_02_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  > .title_wrap {
    width: 100%;
    padding-left: 240px;
    > .title {
      font-size: 60px;
      font-weight: 500;
      line-height: 90px;
      padding-top: 240px;
    }
  }
  > .content {
    width: 850px;
    align-self: flex-end;
    // margin-right: 240px;
    margin-right: 5%;
    > .sub_title {
      padding-top: 150px;
      font-size: 30px;
      font-weight: 400;
      line-height: 55px;
    }
    > .card_wrap {
      padding-top: 50px;
      > .card {
        width: 100%;
        margin-bottom: 200px;
        cursor: pointer;
        > .img_box {
          width: 100%;
          overflow: hidden;
          > img {
            width: 100%;
            height: 100%;
            transition: 0.5s;
          }
        }
        > .name {
          // width: 500px;
          font-size: 40px;
          font-weight: 500;
          line-height: 60px;
          padding-top: 13px;
        }
        > .des {
          padding-top: 10px;
          font-size: 24px;
          font-weight: 400;
          line-height: 45px;
        }
        > button {
          margin-top: 35px;
          font-size: 20px;
          font-weight: 500;
          line-height: 40px;
          border: none;
          background-color: transparent;
          transition: 0.5s;
          > span {
            margin-left: 7px;
          }
        }
      }
      > .card:last-child {
        margin-bottom: 0px;
      }
      > .card:hover {
        > .img_box {
          > img {
            scale: 1.1;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  // 테블릿 세로
  #business_info_type_02_wrap {
    > .title_wrap {
      padding-left: 5%;
      > .title {
        padding-top: 40px;
        font-size: 20px;
        line-height: 35px;
      }
    }
    > .content {
      margin: auto;
      width: 90%;
      align-self: center;
      > .sub_title {
        font-size: 14px;
        line-height: 20px;
        padding-top: 64px;
      }
      > .card_wrap {
        padding-top: 25px;
        > .card {
          margin-bottom: 50px;
          > .name {
            font-size: 16px;
            line-height: 20px;
          }
          > .des {
            font-size: 14px;
            line-height: 20px;
          }
          > button {
            margin-top: 20px;
            font-size: 14px;
            line-height: 15px;
            > span {
              display: inline-block;
              width: 12px;

              > img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿 가로
  #business_info_type_02_wrap {
    > .title_wrap {
      padding-left: 5%;
    }
    > .content {
      margin: auto;
      width: 90%;
      align-self: center;
      > .card_wrap {
        > .card {
          margin-bottom: 100px;
        }
      }
    }
  }
}
