.page_template_wrap {
  max-width: 1920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  > .banner {
    width: 100%;
    height: 580px;
    display: flex;
    align-items: center;
    font-size: 46px;
    font-weight: bold;
    color: white;
    padding-left: 15%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  > .tap_wrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 130px;
    padding-left: 15%;
    background-color: white;
    border-bottom: 1px solid #999999;
    > ul {
      display: flex;
      align-items: center;
      height: 100%;
      > li {
        display: flex;
        min-width: 72px;
        height: 100%;
        flex-direction: column;
        align-items: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 25px;
        padding: 50px 0px 0px 0px;
        margin: 0px 50px;
        color: var(--maincolorgray);
        cursor: pointer;
      }
      > li:first-child {
        padding-left: 0px;
      }
      > li:last-child {
        padding-right: 0px;
      }
      > .active {
        color: var(--maincolorblack);
      }
      > .active::after {
        content: "";
        // min-width: 100%;
        // max-width: 72px;
        width: 72px;
        display: block;
        margin-top: auto;
        border-bottom: 5px solid #111111;
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .page_template_wrap {
    > .banner {
      padding-left: 10%;
      background-repeat: no-repeat;
      // background-size: 100% 100%;
      // background-size: cover;
      font-size: 25px;
    }
    > .tap_wrap {
      height: 50px;
      padding-left: 3%;
      overflow: hidden;
      overflow-x: visible;
      > ul {
        > li {
          font-size: 14px;
          padding-top: 10px;
          margin: 0 10px;
        }
        > .active::after {
          border-bottom: 1px solid #111111;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
}
