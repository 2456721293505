.saas_test_bed_overview {
  max-width: 1920px;
  width: 100%;
  > .title {
    width: 70%;
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    padding-top: 15%;
    padding-bottom: 10%;
    padding-left: 15%;
    color: var(--maincolorblack);
  }
  > .overview {
    width: 100%;
    padding-left: 15%;
    > img {
      width: 100%;
    }
  }
  > .floor_wrap {
    padding-left: 15%;
    padding-right: 5%;
    > .title {
      font-size: 60px;
      font-weight: 500;
      line-height: 80px;
      padding: 15% 0% 10% 0%;
    }
    > .upper {
      display: flex;
      justify-content: space-around;
      > .img_box {
        > img {
          width: 100%;
        }
      }
      > .img_box:nth-child(1) {
        width: 40%;
      }
      > .img_box:nth-child(2) {
        width: 53%;
      }
    }
    > .under {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 10%;
      > .card {
        max-width: 590px;
        width: 48%;
        margin-bottom: 10%;
        > .img_box {
          width: 100%;
          > img {
            width: 100%;
          }
        }
        > .name {
          font-size: 40px;
          font-weight: 500;
          line-height: 60px;
          padding-top: 1%;
        }
        > .list_wrap {
          padding-top: 1%;
          > ul {
            font-size: 24px;
            font-weight: 400;
            line-height: 45px;
            > li {
              list-style: disc;
              margin-left: 40px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .saas_test_bed_overview {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .floor_wrap {
      > .title {
        font-size: 20px;
        line-height: 25px;
      }
      > .under {
        justify-content: end;
        > .card {
          max-width: none;
          width: 100%;
          > .name {
            font-size: 20px;
            line-height: 20px;
          }
          > .list_wrap {
            > ul {
              font-size: 14px;
              line-height: 25px;
              > li {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .saas_test_bed_overview {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .floor_wrap {
      > .title {
        font-size: 40px;
        line-height: 50px;
      }
      > .under {
        justify-content: end;
        > .card {
          max-width: none;
          width: 90%;
          > .name {
            font-size: 30px;
            line-height: 35px;
          }
          > .list_wrap {
            > ul {
              font-size: 16px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  // 테블릿
  .saas_test_bed_overview {
    > .title {
      padding-left: 5%;
    }
    > .overview {
      padding-left: 5%;
    }
    > .floor_wrap {
      padding-left: 5%;
      > .upper {
        align-items: end;
        > .img_box {
          > img {
            width: 100%;
          }
        }
        > .img_box:nth-child(1) {
          width: 45%;
        }
        > .img_box:nth-child(2) {
          width: 44%;
        }
      }
    }
  }
}
