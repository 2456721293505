#academy_content_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  color: var(--maincolorblack);
  > .title {
    font-size: 60px;
    font-weight: 500;
    padding-top: 15%;
    padding-left: 15%;
  }
  > .contents {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 3%;
    padding-bottom: 200px;
    .card_wrap {
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
      > .card {
        width: 23%;
        margin-top: 8%;
        > .img_box {
          width: 100%;
          margin-bottom: 6px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        > .name {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 2%;
        }
        > .date {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  #academy_content_wrap {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .contents {
      .card_wrap {
        > .card {
          width: 48%;
          > .name {
            font-size: 12px;
            line-height: 15px;
          }
          > .date {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  #academy_content_wrap {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .contents {
      .card_wrap {
        > .card {
          width: 33%;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  #academy_content_wrap {
    > .title {
      padding-left: 5%;
    }
    > .contents {
      padding-left: 5%;
      padding-right: 6%;
    }
  }
}
