.design_solution_content_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding-bottom: 15%;
  color: var(--maincolorblack);
  > .title {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    padding-top: 15%;
    padding-left: 15%;
    color: var(--maincolorblack);
  }
  > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 0 15%;
    > .des {
      padding-top: 10%;
      padding-bottom: 5%;
      max-width: 880px;
      font-size: 30px;
      font-weight: 400;
      line-height: 55px;
    }
    > .flow_wrap {
      max-width: 1250px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      > .circle_wrap {
        display: flex;
        align-items: center;
        > .circle {
          position: relative;
          width: 243px;
          // height: 272px;
          > .img_box {
            width: 100%;
            height: 100%;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          > .text {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0%;
            width: 100%;
            height: 100%;
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;
          }
        }
      }
      > .triangle {
        width: 10px;
        height: 10px;
        border-left: 20px solid var(--maincolorgreen);
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
      > .triangle:last-child {
        display: none;
      }
    }
    > .card_wrap {
      max-width: 850px;
      width: 100%;
      > .card {
        padding-top: 15%;
        > .img_box {
          width: 850px;
          height: 490px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        > .name {
          font-size: 40px;
          font-weight: 500;
          line-height: 60px;
          padding-top: 13px;
        }
        > ul {
          padding-top: 10px;
          margin-left: 40px;
          > li {
            font-size: 24px;
            font-weight: 400;
            line-height: 45px;
            list-style: disc;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  //모바일
  .design_solution_content_wrap {
    > .title {
      font-size: 20px;
      line-height: 25px;
    }
    > .content {
      > .des {
        font-size: 16px;
        line-height: 25px;
      }
      > .flow_wrap {
        flex-wrap: wrap;
        > .circle_wrap {
          width: 40%;
          margin-bottom: 5%;
          > .circle {
            width: 100%;
            > .text {
              font-size: 14px;
              line-height: 15px;
            }
          }
        }
        > .triangle:nth-child(4) {
          display: none;
        }
      }
      > .card_wrap {
        max-width: none;
        > .card {
          > .img_box {
            width: 100%;
          }
          > .name {
            font-size: 16px;
            line-height: 25px;
          }
          > ul {
            > li {
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .design_solution_content_wrap {
    > .title {
      font-size: 40px;
      line-height: 50px;
    }
    > .content {
      > .des {
        font-size: 20px;
        line-height: 35px;
      }
      > .flow_wrap {
        > .circle_wrap {
          > .circle {
            width: 160px;
            > .text {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
      > .card_wrap {
        max-width: none;
        > .card {
          > .img_box {
            width: 100%;
          }
          > .name {
            font-size: 30px;
            line-height: 35px;
          }
          > ul {
            > li {
              font-size: 20px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  //모바일
  .design_solution_content_wrap {
    > .title {
      padding-left: 5%;
    }
    > .content {
      padding: 0 5%;
      > .des {
        max-width: none;
      }
    }
  }
}
