.total_eco_content_wrap {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding-bottom: 20%;
  color: var(--maincolorblack);
  > .contents {
    padding: 0 15%;
    > div {
      display: flex;
      flex-direction: column;
      align-items: end;
      width: 100%;
      > .title {
        align-self: start;
        font-size: 60px;
        font-weight: 500;
        letter-spacing: -2px;
        padding-top: 15%;
      }
    }
    > .g_seed_wrap {
      > .des {
        max-width: 1100px;
        width: 100%;
        font-size: 30px;
        font-weight: 400;
        line-height: 55px;
        padding-top: 10%;
      }
      > .img_box {
        width: 100%;
        padding: 5%;
        > img {
          width: 100%;
        }
        > #s {
          display: none;
        }
      }
    }
    > .construction_standards_wrap {
      // > .example_wrap {
      //   width: 950px;
      //   display: flex;
      //   justify-content: space-between;
      //   flex-wrap: wrap;
      //   padding-top: 10%;
      //   > .img_box {
      //     width: 462px;
      //     height: 277px;
      //     padding-bottom: 30px;
      //     > img {
      //       width: 100%;
      //       height: 100%;
      //     }
      //   }
      // }
      > .process_wrap {
        padding-top: 10%;
        > .img_box {
          width: 100%;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  //모바일
  .total_eco_content_wrap {
    > .contents {
      > div {
        > .title {
          font-size: 20px;
          line-height: 25px;
        }
      }
      > .g_seed_wrap {
        > .des {
          font-size: 16px;
          line-height: 25px;
        }
        > .img_box {
          > #b {
            display: none;
          }
          > #s {
            display: block;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .total_eco_content_wrap {
    > .contents {
      > div {
        > .title {
          font-size: 40px;
          line-height: 50px;
        }
      }
      > .g_seed_wrap {
        > .des {
          font-size: 20px;
          line-height: 35px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .total_eco_content_wrap {
    > .contents {
      padding: 0 5%;
    }
  }
}
