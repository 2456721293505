#netzero_wrap {
  max-width: 1920px;
  width: 100%;
  // height: 1153px;
  border-bottom: 1px solid #999999;
  > .inner {
    position: relative;
    width: 100%;
    // height: 100%;
    > .text_wrap {
      position: absolute;
      // padding-top: 400px;
      // padding-left: 240px;
      padding-top: 20%;
      padding-left: 12%;
      margin-bottom: 10px;
      z-index: 10;
    }
    > .img_box {
      top: 0;
      width: 100%;
      height: 100%;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  //모바일
  #netzero_wrap {
    > .inner {
      > .text_wrap {
        padding-top: 5%;
        padding-left: 5%;
        > h2 {
          font-size: 20px;
          font-weight: 400;
          line-height: 25px;
        }
        > button {
          font-size: 14px;
          font-weight: 400;
          line-height: 15px;
          > span {
            display: inline-block;
            width: 12px;
            > img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  // 테블릿 세로
  #netzero_wrap {
    > .inner {
      > .text_wrap {
        > h2 {
          font-size: 35px;
          font-weight: 400;
          line-height: 35px;
        }
      }
    }
  }
}
