.btn_view_more {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  border: none;
  background-color: transparent;
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    position: relative;
    padding-left: 20px;
  }
}
