.certification_wrap {
  display: flex;
  align-items: end;
  position: relative;
  max-width: 1920px;
  width: 100%;
  padding: 12% 12% 0 12%;
  > .left {
    position: relative;
    width: 33%;
    height: 100%;
    z-index: 1;
    > .title_wrap {
      > h2 {
        font-size: 46px;
        font-weight: bold;
      }
      > .des {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
      }
    }
    > .card_wrap {
      display: flex;
      flex-direction: column;
      padding-top: 140px;
      > .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;
        border-radius: 5px;
        padding: 25px;
        margin-bottom: 37px;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.2);
        > .upper {
          font-size: 30px;
          font-weight: 500;
          line-height: 35px;
          color: #111111;
          padding-bottom: 15%;
        }
        > .under {
          display: flex;
          justify-content: space-between;
          align-items: end;
          > .txt_wrap {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            color: #606060;
          }
        }
      }
    }
  }
  > .right {
    position: relative;
    width: 66%;
    z-index: 1;
  }
  > .bg_img {
    position: absolute;
    position: absolute;
    height: 1400px;
    top: 0;
    position: absolute;
    > img {
      height: 100%;
    }
  }
}

@media (max-width: 767px) {
  //모바일
  .certification_wrap {
    flex-direction: column;
    > .left {
      width: 100%;
      > .title_wrap {
        > h2 {
          font-size: 20px;
          line-height: 25px;
        }
        > .des {
          font-size: 14px;
          line-height: 20px;
        }
      }
      > .card_wrap {
        padding-top: 10%;
        > .card {
          > .upper {
            font-size: 16px;
            line-height: 25px;
          }
          > .under {
            > .txt_wrap {
              font-size: 14px;
              line-height: 25px;
            }
            > .patent_icon_wrap {
              width: 48px;
              > img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    > .right {
      width: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  // 테블릿
  .certification_wrap {
    flex-direction: column;
    > .left {
      width: 100%;
      > .title_wrap {
        > h2 {
          font-size: 35px;
          line-height: 55px;
        }
        > .des {
          font-size: 24px;
          line-height: 45px;
        }
      }
      > .card_wrap {
        padding-top: 10%;
        > .card {
          > .upper {
            font-size: 30px;
            line-height: 35px;
          }
          > .under {
            > .txt_wrap {
              font-size: 16px;
              line-height: 25px;
            }
          }
        }
      }
    }
    > .right {
      width: 100%;
    }
  }
}

@media (max-width: 1440px) {
  .certification_wrap {
    padding-left: 5%;
    padding-right: 5%;
  }
}
